(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/client-error-handler/assets/javascripts/payment.js') >= 0) return;  svs.modules.push('/components/oddset/client-error-handler/assets/javascripts/payment.js');
"use strict";


const {
  ErrorType,
  ErrorReason,
  getTypeReasonFromData
} = svs.components.oddset.common.clientErrors;
const {
  account_balance: accountBalanceComponent
} = svs.components;
const {
  Currency: currency,
  CurrencyFromJupiterString: currencyFromJupiterString
} = svs.utils.format;
const {
  dialog
} = svs.components;
const logger = svs.core.log.getLogger('components:oddset:client-error-handler:payment');

const getAccountBalance = () => new Promise(resolve => {
  accountBalanceComponent.get(true, balance => {
    resolve(currencyFromJupiterString(balance));
  });
});

const displayGenericErrorDialog = () => {
  dialog.api.add(new dialog.Confirm({
    type: dialog.type.ERROR,
    title: 'Något gick fel',
    message: [{
      text: 'Något gick fel vid betalningen, försök igen om en liten stund.',
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Ok'
    }]
  }));
};

const onInsufficientFunds = async () => {
  const balance = await getAccountBalance();
  dialog.api.add(new dialog.Confirm({
    type: dialog.type.DEFAULT,
    branding: svs.ui.dialog.branding.SPORT,
    icon: 'money',
    title: 'Du har inte tillräckligt med pengar på ditt spelkonto för att betala ditt spel.',
    message: [{
      text: "Saldo: ".concat(currency(balance), " kr"),
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Avbryt'
    }, {
      title: 'Sätt in pengar',
      callback: () => {
        const returnUrl = svs.utils.url.makeInternal(svs.utils.url.getLocation().pathname);
        svs.utils.url.navigate("".concat(svs.core.urlMapping.get('transactionsHome'), "?returnUrl=").concat(returnUrl));
      }
    }]
  }));
};

const onOddsChanged = () => {
  dialog.api.add(new dialog.Confirm({
    type: dialog.type.ERROR,
    title: 'Oddsändring',
    message: [{
      text: 'Odds har ändrats för ett eller flera spel. Kontrollera din kupong.',
      type: dialog.message.TEXT
    }],
    actions: [{
      title: 'Ok'
    }]
  }));
};

const onLimitExceeded = async () => {
  const limits = await svs.accountServices.player_timelimits.getPlayerTimeLimits();
  if ((limits === null || limits === void 0 ? void 0 : limits.day.remaining) === '0' || (limits === null || limits === void 0 ? void 0 : limits.week.remaining) === '0' || (limits === null || limits === void 0 ? void 0 : limits.month.remaining) === '0') {
    dialog.api.add(new dialog.Confirm({
      type: dialog.type.DEFAULT,
      icon: 'time-limit',
      branding: svs.ui.dialog.branding.SPORT,
      title: 'Du har nått din tidsgräns',
      message: [{
        text: 'När du har nått din tidsgräns kan du inte betala för spel på Sport och Casino.',
        type: dialog.message.TEXT
      }],
      actions: [{
        title: 'Stäng'
      }, {
        title: 'Till mina gränser',
        callback: () => {
          svs.utils.url.navigate(svs.core.urlMapping.get('myProfileLimits'));
        }
      }]
    }));
  } else {
    displayGenericErrorDialog();
  }
};

const checkRestricitions = async () => {
  const canPlay = await svs.accountservices.player_exclusions.canPlayOnVertical('oddset');

  canPlay && displayGenericErrorDialog();
};

const handlePaymentError = (type, reason) => {
  switch (type) {
    case ErrorType.KAMBI_COUPON:
      switch (reason) {
        case ErrorReason[ErrorType.KAMBI_COUPON].GENERAL:
        case ErrorReason[ErrorType.KAMBI_COUPON].ACCOUNT_BLOCKED:
          checkRestricitions();
          break;
        case ErrorReason[ErrorType.KAMBI_COUPON].LIMIT_EXCEEDED:
          onLimitExceeded();
          break;
        case ErrorReason[ErrorType.KAMBI_COUPON].INSUFFICIENT_FUNDS:
          onInsufficientFunds();
          break;
        default:
          displayGenericErrorDialog();
          break;
      }
      break;
    case ErrorType.KAMBI_BET:
    case ErrorType.KAMBI_SELECTION:
      switch (reason) {
        case ErrorReason[ErrorType.KAMBI_SELECTION].ODDS_CHANGED:
          onOddsChanged();
          break;
        default:
          displayGenericErrorDialog();
          break;
      }
      break;
    default:
      displayGenericErrorDialog();
      break;
  }
};

const onPaymentError = errorDatas => {
  logger.warn('Handling error(s)', JSON.stringify(errorDatas));
  if (!(errorDatas !== null && errorDatas !== void 0 && errorDatas.errors) || !Array.isArray(errorDatas.errors)) {
    handlePaymentError(undefined, undefined); 
    return;
  }
  const error = getTypeReasonFromData(errorDatas.errors[0]);
  handlePaymentError(error.type, error.reason);
};
setGlobal('svs.components.oddset.clientErrorHandler.payment', {
  onPaymentError
});

 })(window);